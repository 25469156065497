<template>
    <div>
        <HeaderImpersonate></HeaderImpersonate>

        <MenuApp v-if="isMobile === true && from === 'maslow'"></MenuApp>

        <HeaderSimulation
            v-else
            :color-styles="colorStyles"
            :from="from">
        </HeaderSimulation>

        <slot :color-styles="colorStyles" :from="from"></slot>

        <ChatFloating v-if="from === 'maslow'" :slightly-up="true"></ChatFloating>
    </div>
</template>

<script>
export default {
    name: "Simulation",
    data: () => ({
        from: 'maslow'
    }),
    computed: {
        isMobile() {
            return useNuxtApp().$isMobile?.value;
        },
        colorStyles() {
            const themes = {
                maslow: {
                    primary: '#F56E58',
                    secondary: '#FBECE3',
                    action: '#F56E58',
                    grey100: '#FAF8F7',
                    grey2: '#F6F4F3'
                },
                valority: {
                    primary: '#A58862',
                    secondary: '#EDE7E0',
                    action: '#1F2945',
                    grey100: '#F5F6F8',
                    grey2: '#F5F6F8'
                }
            };

            let styles = {
                'raw': {},
                'bg': {},
                'text': {},
                'border': {}
            };

            for (const [k, v] of Object.entries(themes[this.from])) {
                styles['raw'][k] = v;
                styles['text'][k] = 'color: ' + v + ';';
                styles['bg'][k] = 'background-color: ' + v + ';';
                styles['border'][k] = 'border-color: ' + v + ';';
            }

            return styles;
        }
    },
    created() {
        if (useRequestHeaders().host != null) {
            this.from = useRequestHeaders().host.includes('capacite-emprunt') ? 'valority' : 'maslow';
        } else if (window != null) {
            this.from = window.location.host.includes('capacite-emprunt') ? 'valority' : 'maslow';
        }
    }
}
</script>

<style scoped lang="scss">

</style>
